import { zodResolver } from "@hookform/resolvers/zod";
import { supabase } from "@lagora/utils/supabase";
import { useStore } from "@nanostores/react";
import type { AuthError } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import { Turnstile } from "@marsidev/react-turnstile";
import z from "zod";
import config from "@packages/config";
import { MODAL_KEYS } from "../../constants";
import { setAuthToken } from "../../services";
import { pushNotification, setModalItemId, user } from "../../store";
import Alert from "../Alert.component";
import Button from "../Button.component";
import Input from "../Input/Input.component";
import Modal from "../Modal/Modal.component";
import QrCodeAuth from "./QrCodeAuth.component";
import InputErrorLabel from "../Input/InputErrorLabel.component";

const authFormSchema = z.object({
  email: z.string().email("L'email est invalide"),
  password: z
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 lettres"),
  captchaToken: z.string({
    message: "Merci de valider le captcha",
  }),
});

type AuthForm = z.infer<typeof authFormSchema>;

const authErrorMapper = {
  invalid_credentials: "Vos identifiants ne sont pas valides",
  lagora_unknown_error:
    "Une erreur inconnue est survenue, notre équipe a été alerté",
};

export default function Auth() {
  const [authenticationType, setAuthenticationType] = useState<
    "login" | "register"
  >("login");

  const $user = useStore(user);

  const [afterSubmitErrorMessage, setAfterSubmitErrorMessage] =
    useState<string>("");

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user) {
        user.set(session.user);
        setAuthToken(session.access_token);
      } else {
        user.set(null);
      }
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AuthForm>({
    resolver: zodResolver(authFormSchema),
  });

  const { trigger } = useSWRMutation<
    unknown,
    AuthError,
    "supabase.auth",
    AuthForm
  >(
    "supabase.auth",
    async (_url, { arg: { captchaToken, ...credentials } }) => {
      setAfterSubmitErrorMessage("");

      if (authenticationType === "login") {
        const { data, error } = await supabase.auth.signInWithPassword({
          ...credentials,
          options: { captchaToken },
        });

        if (error) {
          throw error;
        }

        return data;
      }

      if (authenticationType === "register") {
        const { data, error } = await supabase.auth.signUp({
          ...credentials,
          options: { captchaToken },
        });

        if (error) {
          throw error;
        }

        return data;
      }

      return null;
    },
    {
      onSuccess() {
        if (authenticationType === "login") {
          pushNotification({
            type: "success",
            message: "Vous êtes connecté",
          });
        }

        if (authenticationType === "register") {
          pushNotification({
            type: "success",
            message: "Vous êtes inscrit",
          });
        }

        setModalItemId(null);
      },
      onError(err) {
        if (err.code && err.code in authErrorMapper) {
          setAfterSubmitErrorMessage((authErrorMapper as any)[err.code]);
        } else {
          // TODO: add error handleing about ignred errror
        }
      },
    },
  );

  const onSubmit: SubmitHandler<AuthForm> = async (formData) => {
    await trigger(formData);
  };

  return (
    <>
      {$user ? (
        <details
          className="dropdown dropdown-end"
          data-testid="Auth__ProfileButton"
        >
          <summary className="avatar placeholder cursor-pointer">
            <div className="mask mask-squircle bg-accent w-12">
              <span className="text-xl">
                {$user?.email?.slice(0, 2).toUpperCase()}
              </span>
            </div>
          </summary>
          <ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
            <li>
              <QrCodeAuth />
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  supabase.auth.signOut();
                }}
              >
                Déconnexion
              </button>
            </li>
          </ul>
        </details>
      ) : (
        <Button
          data-testid="Auth__Button"
          onClick={() => {
            setModalItemId(MODAL_KEYS.Auth);
          }}
        >
          Se connecter
        </Button>
      )}

      <Modal id="Auth">
        {afterSubmitErrorMessage && (
          <div className="mb-6">
            <Alert
              notification={{
                id: "",
                message: afterSubmitErrorMessage,
                type: "error",
              }}
            />
          </div>
        )}

        <div>
          <h3 className="font-bold text-lg mb-4">
            {authenticationType === "login" ? "Se connecter" : "S'inscrire"}
          </h3>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
              <div
                className="h-16 skeleton w-[300px]"
                style={{ borderRadius: 0 }}
              >
                <Turnstile
                  siteKey={config.cloudflare.turnstileClientKey}
                  onSuccess={(token) => {
                    setValue("captchaToken", token);
                  }}
                />
              </div>

              <InputErrorLabel error={errors.captchaToken?.message} />
            </div>

            <div className="form-control">
              <Input
                placeholder="email@example.com"
                id="email"
                type="email"
                label="Email"
                data-testid="Auth__EmailInput"
                {...register("email")}
                error={errors.email?.message}
              />
            </div>

            <div className="form-control">
              <Input
                placeholder="*******"
                id="password"
                type="password"
                label="Mot de passe"
                data-testid="Auth__PasswordInput"
                {...register("password")}
                error={errors.password?.message}
              />
            </div>

            <div className="form-control">
              <button
                type="submit"
                className="btn btn-primary"
                data-testid="Auth__SubmitButton"
              >
                {authenticationType === "login" ? "Se connecter" : "S'inscrire"}
              </button>
            </div>
          </form>

          <p className="text-center mt-4">
            <span className="mr-2">
              {authenticationType === "login"
                ? "Vous n'avez pas encore de compte ?"
                : "Déjà un compte ?"}
            </span>

            <Button
              onClick={() => {
                if (authenticationType === "login") {
                  setAuthenticationType("register");
                }

                if (authenticationType === "register") {
                  setAuthenticationType("login");
                }
              }}
            >
              {authenticationType === "login" ? "S'inscrire" : "Se connecter"}
            </Button>
          </p>
        </div>
      </Modal>
    </>
  );
}
