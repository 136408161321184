import { supabase } from "@lagora/utils/supabase";
import QrCode from "qrcode";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { MODAL_KEYS } from "../../constants";
import { setModalItemId } from "../../store";
import Alert from "../Alert.component";
import Modal from "../Modal/Modal.component";

function QrCodeAuth() {
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");

  const {
    trigger: generateQrCode,
    isMutating,
    error,
  } = useSWRMutation(
    "supabase.auth.mfa.enroll",
    async () => {
      setQrCodeUrl("");

      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        throw error;
      }

      if (!session) {
        throw new Error("`session` not found");
      }

      return await QrCode.toDataURL(session.refresh_token);
    },
    {
      onSuccess(data) {
        if (data) {
          setQrCodeUrl(data);
        }
      },
    },
  );

  return (
    <>
      <button
        data-testid="QrCodeAuth__Button"
        className="flex justify-between"
        type="button"
        onClick={async () => {
          try {
            await generateQrCode();
          } catch (error) {
            // TODO: handle error notify sentry
          }

          setModalItemId(MODAL_KEYS.QrCodeAuth);
        }}
      >
        <span>Connexion mobile</span>

        {isMutating && <span className="loading loading-spinner loading-sm" />}
      </button>

      <Modal id={MODAL_KEYS.QrCodeAuth}>
        <h2 className="text-xl font-bold">Connexion mobile</h2>

        {qrCodeUrl && (
          <div className="flex justify-center h-72">
            <img
              data-testid="QrCodeAuth__Image"
              className="w-72"
              src={qrCodeUrl}
              alt="Authentication QR Code"
            />
          </div>
        )}

        {error && (
          <div className="my-4">
            <Alert
              notification={{
                id: "QrCodeAuth",
                message:
                  "La génération du QRCode n'a pas fonctionné. Merci de ré-essayer dans 3 minutes",
                type: "error",
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default QrCodeAuth;
