import "./InputErrorLabel.css";

type InputErrorLabelProps = {
  error?: string | undefined;
};

export default function InputErrorLabel(props: InputErrorLabelProps) {
  const { error } = props;

  return (
    <div className="label">
      <span className="InputErrorLabel">{error}</span>
    </div>
  );
}
